section.contact.closed {
    transform: translate(45%, -100px);
    background-position-x: -150px;
    width: 50%;
    max-width: 600px;
    scale: .8;
    pointer-events: none;
    filter: blur(40px) brightness(200%);
    opacity: 0;
    visibility: hidden;
}
section.contact {
    position: fixed;
    top: 105px;
    z-index: 3;
    border: 1px solid black;
    border-radius: 10px;
    background: rgba(255, 255, 255, .8);
    /* background: linear-gradient(to right, rgba(255, 255, 255, .8) 0% 90%, var(--red) 90% 92.5%, var(--star) 92.5% 95%, var(--dark) 95% 97.5%, rgba(255, 255, 255, .8) 97.5% 100%); */
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, .8);
    width: 94%;
    max-width: 1070px;
    min-height: 70px;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    display: flex;
    overflow: hidden;
    transition: .3125s;
}
section.contact .list {
    margin: 0 auto;
    padding: 5px 15px;
    display: flex;
    gap: 10px;
    overflow-x: scroll;
}
section.contact .list button {
    font-size: 20px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: center;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background: none;
    white-space: nowrap;
    transition: .15625s;
}
section.contact .list button:hover {
    background-color: rgba(0, 0, 0, .15)
}
section.contact .list button img {
    width: 70px;
}
section.contact .close-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to right, transparent, white 45%);
    padding-left: 40px;
}
section.contact button.for-mobile.close {
    display: none;
    height: 100%;
    min-width: 89px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    font-size: 15px;
    border: none;
    background: none;
}
section.contact button.for-mobile.close img {
    width: 35px;
}

button.contact.for-mobile {
    height: 60px;
    width: 60px;
    background-size: 40px;
    /* background-size: 70%; */
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    bottom: 22.5px;
    right: 15px;
    z-index: 3;
    background-image: url(../assets/social/communicate.png);
    background-color: rgba(255, 255, 255, .85);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid black;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .25);
    transition: .3125s;
}
button.contact.for-mobile.hidden {
    /* width: 75px;
    height: 75px;
    filter: blur(5px);
    border-radius: 10px;
    opacity: 0; */

    /* filter: blur(5px); */
    /* opacity: 0; */
    width: 94.5vw;
    height: 87px;
    transform: translate(5px, 8px);
    box-shadow: none;
    border-width: 0;
    /* border: none; */
    /* visibility: hidden; */
}
button.contact.for-mobile img {
    display: none;
    height: 40px;
}

@media (prefers-color-scheme: dark) {
    section.contact.closed {
        filter: blur(40px) brightness(50%);
    }
    section.contact {
        width: 99%;
        border-color: white;
        background: rgba(0, 0, 0, .65);
    }
    section.contact .list button:hover {
        background-color: rgba(255, 255, 255, .15);
    }
    section.contact .close-bg {
        background: linear-gradient(to right, transparent, black 45%)
    }
    button.contact.for-mobile {
        background-color: rgba(0, 0, 0, .75);
        border-color: white;
        background-image: url('../assets/social/communicate-dark.png');
    }
}
@media (max-width: 800px) {
    section.contact.closed {
        scale: .8;
        width: 40%;
        transform: translate(40%, 0);
    }
    section.contact {
        top: auto;
        bottom: 15px;
        z-index: 3;

        box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);

        transition: .2083s;
    }
    section.contact .list {
        gap: 5px;
        padding-right: 102px;
    }
    section.contact .list button {
        font-size: 15px;
        transition: .2083s;
    }
    section.contact .list button img {
        width: 35px;
    }
    section.contact button.for-mobile.close {
        display: flex;
    }
}