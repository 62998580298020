.home .block#new-life {
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 350px;
    padding: 15px;
}
.home .block#new-life h1:not(b) {
    font-weight: normal;
    margin: auto 0;
    font-size: 70px;
    z-index: 2;
}
.home .block.tr#new-life h1 {
    text-align: right;
}
.home .block#new-life .lines {
    width: 50%;
    color: transparent;
    font-size: 1px;
    displaY: flex;
    flex-direction: column;
    gap: 15px;
    position: absolute;
    top: 10%;
    right: 0;
    user-select: none;
}
.home .block.tr#new-life .lines {
    top: auto;
    bottom: 10%;
    right: auto;
    left: 0;
    transform: scaleX(-1);
    width: 45%;
}
.home .block#new-life .lines div {
    height: 15px;
}
.home .block#new-life .lines .red {
    background: linear-gradient(to right, transparent, var(--red) 80%);
}
.home .block#new-life .lines .star {
    background: linear-gradient(to right, transparent, var(--star) 80%);
}
.home .block#new-life .lines .dark-color {
    background: linear-gradient(to right, transparent, var(--dark) 80%);
}
.home.halloween .block#new-life .lines .red {
    background: linear-gradient(to right, transparent, #C70D3A 80%);
}
.home.halloween .block#new-life .lines .star {
    background: linear-gradient(to right, transparent, #ED5107 80%);
}
.home.halloween .block#new-life .lines .dark-color {
    background: linear-gradient(to right, transparent, #AD49E1 80%);
}
.home .block#new-life img {
    display: none;
    height: 100px;
    position: absolute;
    right: 0px;
    top: 40px;
}

.home .block#work-with-me {
    padding: 1% 0;
    font-size: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    height: 30vh;
}
.home .block#work-with-me h4 {
    margin: 0;
}
/* .home .block#work-with-me h4 .red {
    color: var(--red);
}
.home .block#work-with-me h4 .star {
    color: var(--star);
}
.home .block#work-with-me h4 .dark {
    color: var(--dark)
} */
.home .block#work-with-me h4.sp {
    font-weight: normal;
}
.home .block#work-with-me button {
    border: 1px solid black;
    background: none;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 10px 16px;
    font-size: x-large;
    margin-top: 20px;
    background-image: linear-gradient(135deg, white 0% 85%, var(--red) 85% 90%, var(--star) 90% 95%, var(--dark) 95% 100%);
    background-position-y: 10vh;
    background-repeat: no-repeat;
    transition: .3125s;
}
.home .block#work-with-me button:hover {
    background-position-y: center;
    scale: 1.1;
}
.home .block#work-with-me button:active {
    scale: .95
}

.home .block#imagination {
    padding: 10px 0;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}
.home .block#imagination img {
    width: 100%;
    margin: -120px;
    z-index: 0;
}
.home .block#imagination .top, .home .block#imagination .bottom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home .block#imagination h4 {
    text-align: center;
    font-size: 40px;
    margin: 5px;
}
.home .block#imagination h4:not(b) {
    font-weight: normal;
}
.home .block#imagination p {
    width: 80%;
    text-align: center;
    font-size: 20px;
    margin: 10px;
}
h2 {
    margin: 0;
    margin-top: 80px;
    text-align: center;
    font-size: 40px;
}

/* .home .block#mail {
    background-image: url("assets/Email-bg.jpg");
    background-size: cover;
    background-position: center;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.home .block#mail div {
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    background-image: url(assets/mail.png);
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    padding: 10px;
    width: 425px;
}
.home .block#mail h4 {
    font-size: 30px;
    margin: 0;
}
.home .block#mail form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding: 20px 5px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
}
.home .block#mail form input {
    background-color: #F0F0F0;
    font-size: 20px;
    border: 1px solid black;
    border-radius: 1px;
    padding: 5px;
}
.home .block#mail form input::placeholder {
    font-weight: lighter;
}
.home .block#mail form button {
    border: 1px solid black;
    background-color: white;
    font-size: 20px;
    margin-top: 10px;
} */

.home section#about-artemkhi .block#main {
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.home section#about-artemkhi .block#main div.image {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.home section#about-artemkhi .block#main div.image img {
    position: relative;
    top: 30px;
    right: 50px;
    scale: 1.25;
    width: 400px;
}
.home section#about-artemkhi .block#main div.text {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.home section#about-artemkhi .block#main div.text h3 {
    font-size: 40px;
    margin: 0;
}
.home section#about-artemkhi .block#main div.text p, .home section#about-artemkhi .block#main div.text a {
    font-size: 20px;
}
.home section#about-artemkhi .block#main div.text a {
    color: black;
}

.home section#about-artemkhi .block#stack {
    padding: 20px 0;
    text-align: center;
}
.home section#about-artemkhi .block#stack h3 {
    font-size: 40px;
    margin: 0;
    text-align: center;
}
.home section#about-artemkhi .block#stack ul.bubbles {
    padding-left: 0;
    margin: 20px 8%;
    list-style-type: none;
    display: flex;
    gap: 15px;
    justify-content: center;
    font-size: 25px;
}
.home section#about-artemkhi .block#stack ul.bubbles li {
    flex: 1;
    text-align: center;
    padding: 5px 15px;
    border-radius: 100px;
    transition: .15625s;
    border: 1px solid black;
    cursor: pointer;
}
.home section#about-artemkhi .block#stack ul.bubbles li:not(#redux):hover {
    /* box-shadow: 5px 5px 0px black; */
    box-shadow: 3px 3px 0px var(--red), 6px 6px 0px var(--star), 9px 9px 0px var(--dark);
    transform: translate(-9px, -9px);
}
/* .home section#about-artemkhi .block#stack a {
    color: black;
    font-size: 20px;
}
.home ul.bubbles #html {
    background-color: #FF8F1F;
    color: white;
}
.home ul.bubbles #js {
    background-color: #FFD53B;
}
.home ul.bubbles #react {
    background-color: black;
    color: #5FE7FF;
}
.home ul.bubbles #react:hover {
    box-shadow: 5px 5px 0px #5FE7FF, 5px 5px 0px 1px black;
    transform: translate(-5px, -5px);
}
.home ul.bubbles #node {
    background-color: #2AC434;
}
.home ul.bubbles #redux {
    background-color: #E167EF;
} */

.home .block#bots {
    padding: 0 20px;
    min-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.home .block#bots h3 {
    font-size: 50px;
}
.home .block#bots h3 span {
    color: #308ADF;
}
.home .block#bots button {
    font-size: x-large;
    background: linear-gradient(135deg, transparent 0% 85%, #B4D5F4 85% 90%, #71AFE9 90% 95%, #308ADF 95% 100%);
    background-position-y: 10vh;
    background-repeat: no-repeat;
    transition: .3125s;
    border-width: 1px;
    border-style: solid;
    border-color: black;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 10px 16px;
}
.home .block#bots button:hover {
    background-position-y: center;
    scale: 1.1;
}
.home .block#bots button:active {
    scale: .95
}

.home .block#portfolio {
    display: flex;
    flex-direction: row;
    padding: 25px;
}
.home .block#portfolio .text, .home .block#portfolio .image {
    flex: 1;
}
.home .block#portfolio .text {
    text-align: center;
}
.home .block#portfolio .text h3 {
    font-size: 50px;
    margin: 10px 0;
}
.home .block#portfolio .text button {
    border: 1px solid black;
    background: none;
    border-radius: 5px;
    text-transform: uppercase;
    padding: 10px 16px;
    font-size: x-large;
    margin-top: 20px;
    background-image: linear-gradient(135deg, white 0% 85%, var(--red) 85% 90%, var(--star) 90% 95%, var(--dark) 95% 100%);
    background-repeat: no-repeat;
    background-position-y: 10vh;
    transition: .3125s;
}
.home .block#portfolio .text button:hover {
    scale: 1.1;
    background-position-y: center;
}
.home .block#portfolio .text button:active {
    scale: 0.95;
}
.home .block#portfolio .image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.home .block#portfolio .image .main {
    width: 400px;
    height: 200px;
    border-radius: 15px;
    background-color: green;
    box-shadow: 7.5px 7.5px 0px var(--red), 15px 15px 0px var(--star), 22.5px 22.5px 0px var(--dark);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translate(-15px, -10px);
}

@media all and (max-width: 800px) {
    .home .block#new-life {
        height: 40vh;
        min-height: 200px;
    }
    .home .block#new-life .lines {
        gap: 10px;
        top: 15%;
    }
    .home .block.tr#new-life .lines {
        top: auto;
        bottom: 15%;
    }
    .home .block#new-life .lines div {
        height: 10px;
    }
    .home .block#new-life img {
        height: 50px;
    }
    .home .block#new-life h1:not(b) {
        font-size: 40px;
        max-width: 95%;
    }
    .home .block#work-with-me h4 {
        text-align: center;
        font-size: 30px;
        width: 90%;
    }
    .home .block#work-with-me button {
        font-size: 18px;
    }
    .home .block#imagination img {
        margin: 0;
    }
    .home .block#imagination h4 {
        margin: 0;
        font-size: 30px;
        width: 95%;
    }
    .home section#about-artemkhi .block#main {
        flex-direction: column-reverse;
    }
    .home section#about-artemkhi .block#main div.image {
        justify-content: center;
        align-items: center;
    }
    .home section#about-artemkhi .block#main div.image img {
        scale: 1;
        top: 0;
        right: 10px;
        margin-bottom: -60px;
    }
    .home section#about-artemkhi .block#main div.text {
        margin-top: 20px;
    }
    .home section#about-artemkhi .block#stack ul.bubbles {
        flex-wrap: wrap;
    }
    .home .block#bots {
        padding: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 15px;
    }
    .home .block#bots h3 {
        font-size: 30px;
        margin: 0px;
    }
    .home .block#bots button {
        font-size: 18px;
    }
    .home .block#portfolio {
        flex-direction: column-reverse;
    }
    .home .block#portfolio .image div.main {
        transform: translate(-10px, -10px);
    }
    .home .block#portfolio .text h3 {
        font-size: 40px;
    }
    .home .block#portfolio .text button {
        font-size: 18px;
    }
}
@media (prefers-color-scheme: dark) {
    .home .block#work-with-me button {
        border-color: white;
        background-image: linear-gradient(135deg, black 0% 85%, var(--red) 85% 90%, var(--star) 90% 95%, var(--dark) 95% 100%);
    }
    .home section#about-artemkhi .block#main div.text a {
        color: white;
    }
    .home section#about-artemkhi .block#stack ul.bubbles li {
        border-color: white;
    }
    .home .block#bots button {
        border-color: white;
    }
    .home .block#portfolio .text button {
        border-color: white;
        background-image: linear-gradient(135deg, black 0% 85%, var(--red) 85% 90%, var(--star) 90% 95%, var(--dark) 95% 100%);
    }
}