.projects {
    overflow-x: hidden;
    user-select: none;
}
.projects .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 15px;
}
.projects h1 {
    font-size: 40px;
    text-align: center;
    margin: 10px;
}
.projects .filters {
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    user-select: none;
}
.projects .filters p {
    font-size: 20px;
    margin: 0;
}
.projects .filters .arrow {
    transition: .625s;
}
.projects .filters .arrow.opened {
    transform: rotateY(180deg);
}
.projects .filters .buttons {
    margin-right: -15px;
    padding: 15px 15px;
    display: flex;
    gap: 5px;
    overflow: scroll;
    width: 100%;
    transition: ease-in-out .625s;
}
.projects .filters .buttons.closed {
    width: 0;
    filter: blur(20px);
    opacity: 0;
    overflow: hidden;
}
.projects .filters .buttons button {
    font-size: 18px;
    padding: 5px 15px;
    border: 1px solid black;
    background: none;
    color: black;
    white-space: nowrap;
    border-radius: 30px;
    transition: .15625s;
}
.projects .filters .buttons button:hover {
    /* scale: 1.05; */
    font-weight: bold;
    /* box-shadow: 0px 0px 2.5px 1.25px rgba(0, 0, 0, .25); */
}
.projects .filters .buttons button.chosen:not(.for-study) {
    font-weight: bold;
    padding: 5px 45px;
}
.projects .filters .buttons button.for-study {
    background-image: linear-gradient(90deg, var(--red) 0% 2.33%, var(--star) 2.33% 4.66%, var(--dark) 4.66% 7%, transparent 7% 100%);
    background-position-x: -2em;
    background-repeat: no-repeat;
    padding: 5px 30px;
    transition: .3125s;
}
.projects .filters .buttons button.for-study.chosen {
    background-position: 0px;
    font-weight: bold;
}
.projects .filter-cancel {
    overflow: hidden;
    height: 20px;
    transition: .3125s;
}
.projects .filter-cancel.closed {
    height: 0;
    filter: blur(20px);
    opacity: 0;
}
.projects .filter-cancel button {
    font-size: 15px;
    background: none;
    border: none;
    color: black;
    opacity: .5;
}
.projects .list .no-result {
    opacity: .25;
    margin: 35px;
    transition: .625s;
}
.projects .list .no-result.closed {
    filter: blur(20px);
    opacity: 0;
    overflow: hidden;
    height: 0;
    margin: 0;
}
.projects .list {
    user-select: none;
    width: 95%;
    flex: 1;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.projects .list .item {
    height: 400px;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    border: 1.5px solid transparent;
    transition: .3125s;
    cursor: pointer;
}
.projects .list .item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.projects .list .item.closed {
    margin: -10px;
    border: none;
    height: 0;
    filter: blur(20px);
    opacity: 0;
    transition: .625s;
}
.projects .list .item:hover {
    /* box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, .5); */
    scale: 1.05;
    transition: .3125s;
}
.projects .list .item:active {
    /* box-shadow: none; */
    scale: .95;
    transition: .3125s;
}
.projects .list .item.animated {
    filter: blur(20px);
    opacity: 0;
    scale: 1.2;
}
.projects .list .item .title {
    min-height: 20%;
    padding: 20px;
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    position: absolute;
    width: calc(100% - 20px);
    top: 0px;
}
.projects .list .item .title .name {
    margin: 0;
}
.projects .list .item .title .for-study {
    font-size: 18px;
    padding: 3px 5px;
    border: 1px solid transparent;
    border-radius: 5px;
    text-align: center;
}

.project {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    transition-duration: .625s;
}
.project.closed {
    backdrop-filter: none;
    pointer-events: none;
}
.project .window {
    gap: 0;
    width: 98%;
    max-width: 900px;
    height: 60vh;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    transition: .3125s;
}
.project.closed .window {
    scale: .85;
    opacity: 0;
    filter: brightness(2);
}
.project .window .heading {
    user-select: none;
}
.project .window button.back {
    width: 100%;
    text-align: left;
    font-size: 20px;
    background: none;
    border: none;
    padding: 10px 20px;
    transition: .15625s;
}
.project .window button.back:hover {
    padding-left: 15px;
}
.project .window button.back:active {
    transform: translateX(-5px);
}
.project .window button.back span {
    display: inline-block;
    transform: translateX(10px);
    opacity: 0;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    transition: .15625s;
}
.project .window button.back:hover span {
    transform: none;
    opacity: 1;
    filter: none;
    -webkit-filter: none;
}
.project .window .body {
    margin: auto 0;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0px 15px;
}
.project .window .text {
    width: 60%;
    max-height: 50vh;
    padding-left: 10px;
    overflow-x: hidden;
    user-select: text;
}
.project .window .text div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: scroll;
}
.project .window .text .title {
    font-size: 40px;
    margin: 0;
}
.project .window .text .for-study {
    align-self: flex-start;
    font-size: 18px;
    padding: 5px 15px;
    margin: 0;
    border: 1px solid black;
    border-radius: 5px;
}
.project .window .text .description, .project .window .text .used, .project .window .text .duration {
    font-size: 18px;
    margin: 5px 0px;
}
.project .window .image {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.project .window .image img {
    width: 100%;
    max-width: 600px;
    transition: .625s;
    border-radius: 5px;
    border: 1px solid black;
    pointer-events: all;
}
.project .window .image img:hover {
    scale: .90;
}
.project .window .image img:active {
    scale: 1;
    filter: blur(5px);
    box-shadow: none;
}
.project .window .image .to-site {
    font-size: 20px;
    padding: 5px 15px;
    border-radius: 5px;
    transition: .3125s;
}
.project .window .image .to-site:hover {
    scale: 1.1;
}
.project .window .image .to-site:active {
    scale: .95;
}
/*  .project .window {
    background-color: white;
    border-radius: 20px;
    display: flex;
    width: 90vw;
    max-width: 900px;
    height: 80vh;
    max-height: 800px;
    transition: .3125s;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}
.project.closed .window {
    filter: blur(40px) brightness(.75);
    opacity: 0;
    scale: .9;
}
.project .window button.back {
    width: 100%;
    font-size: 20px;
    text-align: left;
    border: none;
    background: none;
    color: black;
    margin-bottom: auto;
}
.project .window img {
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    border: 1px solid black;
    transition: .625s;
}
.project .window img:hover {
    border: none;
}
.project .window img:active {
    scale: 1.1;
    filter: blur(10px);
}
.project .for-study {
    margin: 0;
    margin-bottom: 10px;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 4px;
}
.project .description {
    max-width: 600px;
}
.project .used {
    max-width: 500px;
    text-align: center;
}
.project .used .tools {
    font-weight: bold;
}
.project .used .tools .separator {
    color: black;
    font-weight: normal;
}
.project button.to-site {
    background: none;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px 15px;
    transition: .3125s;
    margin-bottom: auto;
}
.project button.to-site:hover {
    scale: 1.1;
}
.project button.to-site:active {
    scale: .95
} */
/* .project .block {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.project .block.transitioning {
    filter: brightness(5);
    scale: 5;
    color: white;
    transition-duration: 2.5s;
}
.project button.back {
    font-weight: bold;
    font-size: 16px;
    align-self: flex-start;
    padding: 15px;
    border: none;
    background: none;
}
.project .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.project .title img {
    border-radius: 20px;
}
.project .transitioning .title img {
    opacity: 0;
    transition-duration: 2.5s;
}
.project .title h1 {
    font-size: 30px;
    margin: 10px;
}
.project .info {
    text-align: center;
    margin: auto;
    max-width: 600px;
}
.project .info button {
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
    border: none;
} */
@media (prefers-color-scheme: dark) {
    .projects .filters .buttons button {
        border-color: white;
        color: white;
    }
    .projects .filter-cancel button {
        color: white;
    }
    .projects .list .item {
        filter: brightness(.9);
    }
    .project .window {
        background: black;
    }
    .project .window button.back {
        color: white;
    }
    .project .window .text .for-study {
        border-color: white;
    }
    .project .used .tools .separator {
        color: white;
    }
}
@media (max-width: 800px) {
    .projects .filters {
        flex-direction: column;
        overflow: visible;
    }
    .projects .filters .arrow {
        rotate: 90deg;
        transition: .3125s;
    }
    .projects .filters .buttons {
        flex-wrap: wrap;
        align-items: center;
        overflow-x: visible;
        margin-right: 0;
        padding: 5px;
        width: 90vw;
        transition: .3125s;

        position: absolute;
        top: 110%;
        z-index: 3;

        background: rgba(255, 255, 255, .85);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        border: 1px solid black;
        border-radius: 24px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, .25);
        transition: .2038s;
    }
    .projects .filters .buttons button {
        flex: 1;
    }
    .projects .filters .buttons button:hover {
        font-weight: normal;
    }
    .projects .filters .buttons.closed {
        width: 90vw;
        scale: .7;
        translate: 0 -20%;
        filter: blur(20px);
        pointer-events: none;
        visibility: hidden;
    }
    .projects .filters .buttons button.chosen:not(.for-study) {
        background-color: black;
        color: white;
        padding: 5px 15px;
    }
    .projects .list .no-result {
        margin-top: 160px;
    }
    .projects .list .item {
        height: auto;
        transition: .15625s;
    }
    .projects .list .item.animated {
        scale: 2;
        filter: blur(40px);
    }
    .projects .list .item .title {
        padding-top: 7.5px;
        font-size: 18px;
    }
    .projects .list .item .title .for-study {
        font-size: 15px;
    }
    .projects .list .item img {
        margin-top: 50px;
    }
    .project {
        z-index: 300;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
    .project.closed .window {
        border-radius: 100px;
        scale: .8;
        filter: blur(20px);
    }
    .project .window {
        padding: 15px 10px;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        box-shadow: none;
    }
    .project .window button.back {
        min-height: 47px;
    }
    .project .window button.back span {
        opacity: 1;
        filter: none;
        -webkit-filter: none;
    }
    .project .window .body {
        flex-direction: column;
        align-items: stretch;
    }
    .project .window .text {
        width: 100%;
        padding: 0;
    }
    .project .window .text .title {
        font-size: 30px;
        margin: auto;
    }
    .project .window .text .for-study {
        align-self: center;
    }
    .project .window .image {
        width: 100%;
        overflow-x: hidden;
    }
    .project .window .image img {
        max-width: 95%;
    }
    .project .text div.not-for-mobile {
        display: none;
    }
    .project .image div.for-mobile {
        max-height: 34vh;
        overflow-y: scroll;
        font-size: 18px;
        padding-bottom: 60px;
    }
    /* .project .title {
        font-size: 25px;
    }
    .project .title, .project .description, .project .used {
        margin: 5px;
    } */
}
@media (max-width: 800px) and (prefers-color-scheme: dark) {
    .projects .filters .buttons {
        background-color: rgba(0, 0, 0, .5);
        border-color: white;
    }
    .projects .filters .buttons button.chosen:not(.for-study) {
        background-color: white;
        color: black;
    }
    .project .window .text .for-study {
        border-color: white;
    }
}