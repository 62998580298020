@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

:root {
    --red: #c60000;
    --star: #ffe500;
    --dark: #7b01f9;
}
::selection {
    background: rgba(0, 0, 0, 1);
    color: white;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Nunito Sans", sans-serif;
    font-style: italic;
}
p, a, button, form, input, ul, textarea, select {
    /* font-family: "Comfortaa", sans-serif; */
    font-family: "Nunito Sans", sans-serif;
    /* font-weight: 100; */
}
img {
    user-select: none;
    pointer-events: none;
}
button, a {
    cursor: pointer;
    color: black;
}
html {
    scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}
.for-mobile {
    display: none;
}
.dark {
    display: none;
}
body {
    margin: 0;
    margin-bottom: 40px;
    position: relative;
    background: white;
}
#root {
    display: flex;
    flex-direction: column;
    align-items: center;
}
header {
    user-select: none;
    z-index: 4;
    position: sticky;
    top: 0;
    width: 99.5vw;
    max-width: 1080px;
    background: linear-gradient(to bottom, white 75%, transparent);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
}
header nav {
    background-color: rgba(255, 255, 255, .25);
    width: 99%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    gap: 10px;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    border-radius: 10px;
    overflow: hidden;
}
header button.for-logo {
    background: none;
    border: none;
    height: 70px;
    padding: 0;
}
header .logo {
    height: 70px;
    margin: 0 5px;
}
header .main-buttons {
    display: flex;
    gap: 10px;
}
header .main-buttons button {
    border: none;
    background: none;
    font-size: x-large;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-bottom: -2px;
}
header .main-buttons button .stripe {
    height: 1px;
    width: 0;
    background-image: linear-gradient(to right,var(--red) 0% 33%, var(--star) 33% 66%, var(--dark) 66% 100%);
    background-size: 250%;
    background-repeat: no-repeat;
    transition: .3125s;
}
header .main-buttons button .stripe.selected {
    width: 100%;
    background: black;
}
header .main-buttons button:hover .stripe:not(.selected) {
    width: 100%;
    background-size: 100%;
}
header .contact {
    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
}
header .lines {
    margin-left: auto;
    position: relative;
    min-height: 80px;
    color: transparent;
}
header .lines img {
    position: absolute;
    top: 0;
    right: 0;
    height: 80px;
    margin-right: -6px;
    transition: .625s;
}
header .lines img.colorful.animated {
    transform: rotateY(45deg);
    scale: 1.5;
    opacity: 0;
}
header .lines img.blue.animated {
    transform: rotateY(-45deg);
    filter: blur(20px) brightness(5);
    scale: 1.5;
    opacity: 0;
}
header .contact button {
    background: none;
    border: none;
    font-size: x-large;
    font-family: "Nunito Sans", sans-serif;
    font-style: italic;
    font-weight:800;
    text-transform: uppercase;
}
main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    width: 97vw;
    max-width: 1050px;
    min-height: 80vh;
}
main section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
main .block {
    flex: 1;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    background-color: white;
}
footer {
    margin: 5px auto;
    display: flex;
    align-items: stretch;
    gap: 10px;
    width: 97vw;
    max-width: 1050px;
}
footer div.left, footer div.right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
}
footer button:not(.language) {
    font-size: 18px;
    background: none;
    border: none;
    padding: 0;
}
footer div.left {
    align-items: flex-start;
}
footer div.left button {
    text-align: left;
}
footer div.right {
    justify-content: center;
    align-items: flex-end;
    text-align: right;
}
footer button.language {
    padding: 0;
    font-size: 18px;
    background: none;
    border: none;
    text-align: right;
}

footer .language-place {
    position: relative;
    margin: auto 0;
}
footer .language-place .window {
    position: absolute;
    right: -10px;
    bottom: 125%;
    background-color: rgba(255, 255, 255, .8);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    /* box-shadow: 5px 5px 20px rgba(0, 0, 0, .25); */
    border: 1px solid black;
    padding: 15px;
    border-radius: 20px;
    overflow: hidden;
    z-index: 200;
    transition: .3125s;
}
footer .language-place .window.closed {
    scale: .7;
    transform: translate(20%, 20%);
    opacity: 0;
    box-shadow: none;
    filter: blur(40px);
    pointer-events: none;
    visibility: hidden;
    transition: .3125s;
}
footer .language-place .window p {
    font-size: 18px;
    text-align: center;
}
footer .language-place .window .buttons {
    display: flex;
    gap: 5px;
}
footer .language-place .window button {
    border: 1px solid black;
    font-size: 18px;
    padding: 5px 10px;
    border-radius: 5px;
    transition: .15625s;
}
footer .language-place .window button:hover {
    scale: 1.05;
    box-shadow: 2.5px 2.5px 5px rgba(0, 0, 0, .25);
    transform: translate(-2.5px, -2.5px);
}
footer .language-place .window button span {
    font-size: 13px;
}
footer .copyright {
    font-size: 14px;
    margin: 0;
    margin-top: auto;
}

@keyframes for-hint {
    from {
        opacity: 0;
        transform: translate(50%, 0);
    }
    to {
        opacity: 1;
        transform: none;
    }
}
@media all and (max-width: 800px) {
    .for-mobile {
        display: block;
    }
    .for-mobile.dark {
        display: none;
    }
    .not-for-mobile {
        display: none;
    }
    /* .hint {
        font-size: 20px;
        position: fixed;
        top: 25px;
        left: 100px;
        animation: for-hint .625s;
    }
    .hint.closed {
        opacity: 0;
        filter: blur(40px);
        transition: 1.25s;
    } */
    header {
        /* background-image: linear-gradient(to bottom, white 10%, transparent 15% 100%); */
        pointer-events: none;
        background: none;
        background-repeat: no-repeat;
        position: fixed;
        align-items: flex-start;
        transition: .3125s;
    }
    header nav {
        pointer-events: all;
        margin-left: 10px;
        height: 260px;
        max-height: 90vh;
        flex-direction: column;
        align-items: stretch;
        padding: 5px;
        width: 95vw;
        max-width: 250px;
        overflow: hidden;
        gap: 15px;
        position: relative;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, .5);
        background-color: rgba(255, 255, 255, .8);
        backdrop-filter: blur(40px);
        -webkit-backdrop-filter: blur(40px);
        transition: .3125s;
    }
    header.closed nav.long {
        max-width: calc(100% - 32.25px);
        width: 100vw;
        background-color: white;
        border: 1px solid black;
        box-shadow: none;
    }
    header.closed nav {
        position: relative;
        width: 70px;
        height: 70px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
        background: rgba(255, 255, 255, 1);
        transition: .3125s;
    }
    header nav .for-logo {
        z-index: 10;
        align-self: flex-start;
        overflow: visible;
        scale: 1.1;
        transition: .3125s;
    }
    header.closed nav .for-logo {
        position: relative;
        scale: 1;
        transition: .3125s;
    }
    header nav .logo {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, .25);
        margin: 5px;
        transition: .3125s;
    }
    header.closed nav .logo {
        z-index: 20;
        margin: 0;
        box-shadow: none;
        transition: .3125s;
    }
    header nav .hint {
        position: absolute;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
        top: 7px;
        right: 20px;
        z-index: 0;
        opacity: 0;
        filter: blur(5px);
        transition: .3125s;
    }
    header.closed nav:not(.long) .hint {
        right: 5px;
    }
    header.closed nav.long .hint {
        opacity: 1;
        filter: none;
        transition-delay: .078125s;
    }
    header nav .main-buttons {
        margin-top: 15px;
        flex-direction: column;
        align-items: flex-start;
        transition-duration: .625s;
        transition-delay: .15625s;
        overflow-y: scroll;
    }
    header .main-buttons button {
        margin-bottom: 0;
    }
    header .main-buttons button .stripe {
        display: none;
    }
    header .main-buttons button .stripe.selected {
        display: block;
    }
    header.closed nav .main-buttons {
        transform: translate(0, 50%);
        opacity: 0;
        filter: blur(15px);
        transition-duration: .625s;
        transition-delay: .3125s;
    }
    header nav .contact {
        display: none;
        margin: 0;
        justify-content: flex-start;
        transition-duration: .625s;
        transition-delay: .15625s;
    }
    header.closed .contact {
        opacity: 0;
        transform: translate(0, 50%);
        transition-duration: .625s;
        transition-delay: .3125s;
    }
    header nav .lines {
        display: none;
    }
    header nav .contact button {
        height: 50px;
    }
    footer {
        margin-bottom: 65px;
    }
    footer div.left {
        gap: 5px;
    }
    footer .language-place .window {
        right: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, .25);
    }
}
@media all and (prefers-color-scheme: dark) {
    ::selection {
        background-color: var(--dark);
    }
    .not-for-mobile.dark {
        display: block;
    }
    .light {
        display: none;
    }
    body {
        background-color: black;
        color: white;
    }
    main .block {
        background-color: black;
        border-color: white;
    }
    header {
        background: linear-gradient(to bottom, black 75%, transparent);
    }
    header nav {
        background-color: rgba(0, 0, 0, .25);
        border-color: white;
    }
    header.closed nav {
        background-color: rgba(0, 0, 0, .25);
    }
    header .logo.dark {
        display: block;
    }
    header nav .main-buttons button .stripe.selected {
        background-color: white;
    }
    h1, h2, h3, h4, h5, h6, p, a, button, form, input, ul, textarea, select, option {
        color: white;
    }
    footer .language-place .window {
        background-color: rgba(0, 0, 0, .8);
        border-color: white;
    }
    footer .language-place .window button {
        border-color: white;
    }
    footer .language-place .window button:hover {
        box-shadow: 2.5px 2.5px 5px rgba(255, 255, 255, .5);
    }
}
@media (max-width: 800px) and (prefers-color-scheme: dark) {
    .for-mobile.dark {
        display: block;
    }
    .not-for-mobile.dark {
        display: none;
    }
    header {
        background: none;
    }
    header.closed nav {
        background-color: rgba(0, 0, 0, 1);
    }
    header.closed nav.long {
        background-color: black;
        border-color: white;
    }
    header .for-logo.for-mobile .logo.dark {
        display: block
    }
}